import React from 'react';
import styles from './Modal2.module.scss';
import oculusLogo from './oculus.gif';

export default function Modal() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainCard}>
        {/* Header */}
        <header className={styles.header}>
          <div className={styles.headerContent}>
          
            <div className={styles.mainTitle2}>  
              <img src={oculusLogo} alt="Oculus Logo" className={styles.logoImage} /> 
              <span className={styles.logoText}>OCULUS AI</span>
            </div>
          </div>
          <div className={styles.headerLinks}>
            <a href="https://twitter.com/Oculus_Bot" target="_blank" rel="noopener noreferrer" className={styles.headerLink}>
              Twitter
            </a>
            <a href="https://t.me/OfficialOculusBot" target="_blank" rel="noopener noreferrer" className={styles.headerLink}>
              Telegram
            </a>
            <a href="https://docs.oculus.bot" target="_blank" rel="noopener noreferrer" className={styles.headerLink}>
              Documentation
            </a>
            <a href="https://dexscreener.com/ethereum/0x2b62a496f29a8a6d8ead20f20bb76ed65919309f" target="_blank" rel="noopener noreferrer" className={styles.headerLink}>
              Chart
            </a>
          </div>
        </header>

        <img src={oculusLogo} className={styles.logo} />

        <div className={styles.mainTitle}>APE THE BEST OF BOTH WORLDS THROUGH AI WITH $OCU<br /><br />CA: 0xB90dC272EFB78740d9ab15653FADCcf061E2079c</div>

        {/* Bot Descriptions - Side by Side */}
        <div className={styles.botContainer}>
          {/* Ethereum Bot Section */}
          <div className={styles.botCard}>
            <h2 className={styles.botTitle}>Ethereum</h2>
            <p className={styles.botDescription}>
              The OG chain that started it all. Hone your strategy with filters that flag key signals like paid Dextools updates, locked liquidity, and sniper counts—while cutting out tokens with questionable fees and wallets from suspicious sources.
            </p>
            <button className={styles.blueGlowButton}>
              <a href="https://t.me/OculusEthereum_Bot?start=web" target="_blank" rel="noopener noreferrer">
                Launch Ethereum Bot
              </a>
            </button>
          </div>

          {/* Solana Bot Section */}
          <div className={styles.botCard}>
            <h2 className={styles.botTitle2}>Solana</h2>
            <p className={styles.botDescription2}>
              Solana brings fresh opportunities—and higher risks. Test your theories with filters that spotlight solid projects backed by active communities, while dodging potential rug pulls. Take advantage of Jito frontrunning and priority fee settings.
            </p>
            <button className={styles.pinkGlowButton}>
              <a href="https://t.me/OculusSolana_Bot?start=web" target="_blank" rel="noopener noreferrer">
                Launch Solana Bot
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
